import React from "react";
import arrow from "../img/arrow.png";
import eye from "../img/eye.png";
import videoTutorial from "../videos/TUTORIAL_OBP_3.0.mp4";
const HowItWorks = () => {
	return (
		<section className="py-[100px] 2xl:pb-[229px] 2xl:pt-[118px] relative overflow-hidden">
			<img
				src={eye}
				className="hidden lg:block absolute top-16 2xl:top-[120px] xl:left-[calc(50%+270px)] 2xl:left-[calc(50%+470px)]"
				alt=""
			/>
			<div className="container 2xl:max-w-[1388px]">
				<h1 className="text-3xl sm:text-5xl md:text-6xl xl:text-[50px] xl:leading-[64px] 2xl:text-[64px] 2xl:leading-[78px] font-bold mb-8 flex items-center gap-2">
					¿Cómo funciona?{" "}
					<img
						src={arrow}
						className="max-md:w-[50px] max-sm:w-[40px]"
						alt=""
					/>
				</h1>

				<div className="sm:text-md xl:text-lg 2xl:text-xl font-medium mb-12 md:mb-20 xl:mb-[130px]">
					Out Of Home Bit Planner (OBP) es una herramienta avanzada que
					utiliza tecnología de inteligencia artificial para optimizar la
					selección y gestión de espacios publicitarios.
				</div>
				<div className="container flex relative z-10 justify-center items-center mb-12">
					<video width="1200" controls>
						<source src={videoTutorial} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
				</div>
			</div>


		</section>
	);
};



export default HowItWorks;
