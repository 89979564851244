import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Aviso from "./pages/aviso/Aviso";
import Contact from "./pages/contact/Contact";
import Home from "./pages/home/Home";
import Medios from "./pages/medios/Medios";
import Obp from "./pages/obp/Obp";
import Proyectos from "./pages/proyectos/Proyectos";

import { useEffect } from "react";
import Conocenos from "./pages/conocenos/Conocenos";
function App() {
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location.pathname, location.search]);
    return children;
  };

  return (
    <BrowserRouter>
      <Wrapper>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contacto" element={<Contact />} />
          <Route path="/medios" element={<Medios />} />
          <Route path="/proyectos" element={<Proyectos />} />
          <Route path="/conocenos" element={<Conocenos />} />
          <Route path="/obp" element={<Obp />} />
          <Route path="/aviso" element={<Aviso />} />
        </Routes>
      </Wrapper>
    </BrowserRouter>
  );
}

export default App;
