import React from "react";
import img1 from "../img/1.png";
import img2 from "../img/2.png";
import videoGeneral from "../videos/VIDEO_GENERAL_OBP_FINAL.mp4";

const Banner = () => {
	const [open, setOpen] = React.useState(false);
	return (
		<section
			className="py-[90px] pb-[300px] xl:pt-[200px] xl:pb-[205px] relative overflow-hidden"
			onMouseOver={setOpen}
		>
			<div className="container relative z-10">
				<h1 className="text-4xl sm:text-5xl md:text-6xl xl:text-[50px] xl:leading-[64px] 2xl:text-[64px] 2xl:leading-[78px] font-bold mb-8">
					OBP:{" "}
					<span className="text-primary"> Resultados inteligentes</span>{" "}
					<br className="hidden lg:inline-block" /> y visibilidad
					garantizada
				</h1>

				<div className="text-md md:text-lg 2xl:text-xl 2xl:leading-[29px] md:font-medium mb-16 2xl:mb-[112px] lg:max-w-[660px] xl:max-w-[860px] 2xl:max-w-[996px]">
					<video width="1000" controls autoPlay>
						<source src={videoGeneral} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
				</div>

			</div>
			<img
				className="absolute bottom-0 right-0 max-2xl:max-w-[700px] max-xl:max-w-[560px] max-md:max-w-[460px] max-sm:max-w-[400px]"
				src={open ? img2 : img1}
				alt=""
			/>
		</section>
	);
};

export default Banner;
