import React from "react";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer className="bg-title py-16 md:pt-[99px] md:pb-[105px]">
      <div className="container">
        <div className="flex flex-wrap gap-12 lg:gap-5 xl:gap-[100px]">
          <div className="w-full xl:max-w-[337px] lg:max-w-[280px]">
            <Link
              to="/"
              className="block max-w-[150px] md:max-w-[180px] xl:max-w-[228px] mb-5"
            >
              <img src="/img/footer-logo.png" className="w-full" alt="" />
            </Link>
            <div className="md:text-md text-white font-medium">
              Tecnología en publicidad estratégica
            </div>
          </div>
          <div className="w-0 flex-grow">
            <div className="flex flex-wrap gap-4 justify-between mb-9">
              {footer_menu.map((item, index) => (
                <div key={index}>
                  <Link
                    to={item.link}
                    className="block text-white font-bold mb-3 md:font-bold hover:text-primary"
                  >
                    {item.name}
                  </Link>
                  {item.submenu && (
                    <div>
                      {item.submenu.map((subitem, subindex) => (
                        <Link
                          key={subindex}
                          to={subitem.link}
                          className="block text-white font-medium mt-[10px] hover:text-primary"
                        >
                          {subitem.name}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="flex justify-evenly md:justify-between items-center gap-6 flex-wrap">
              <ul className="flex flex-wrap gap-2">
                {social_icons.map((item, index) => (
                  <li key={index}>
                    <Link to={item.link} className="flex w-10 xl:w-[46px]">
                      <img src={item.img} className="w-full" alt="" />
                    </Link>
                  </li>
                ))}
              </ul>
              <div className="flex flex-wrap items-center justify-center gap-4">
                <Link
                  to="/"
                  className="text-white font-medium lg:text-sm xl:text-md"
                >
                  Términos y condiciones
                </Link>
                <div className="hidden sm:block h-6 w-[2px] bg-white mx-3"></div>
                <Link
                  to="/aviso"
                  className="text-white font-medium lg:text-sm xl:text-md"
                >
                  Aviso de privacidad
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
const footer_menu = [
  {
    name: "Inicio",
    link: "/",
  },
  {
    name: "Conócenos",
    link: "/conocenos",
  },
  {
    name: "Medios",
    link: "/medios",
    submenu: [
      {
        name: "Outdoor",
        link: "/medios",
      },
      {
        name: "Indoor",
        link: "/medios",
      },
    ],
  },
  {
    name: "Proyectos",
    link: "/proyectos",
  },
  {
    name: "OBP",
    link: "/obp",
  },
  {
    name: "Contáctanos",
    link: "/contacto",
  },
];
const social_icons = [
  {
    img: "/img/social/1.png",
    link: "https://wa.me/3319617391",
  },
  {
    img: "/img/social/2.png",
    link: "#",
    // contacto@imjmedia.com.mx
  },
  {
    img: "/img/social/3.png",
    link: "https://www.linkedin.com/company/imjmedia-mx/",
  },
  // {
  // 	img: "/img/social/4.png",
  // 	link: "#",
  // },
];
export default Footer;
