import React from "react";
import Layout from "../../components/Layout";

const Aviso = () => {
  return (
    <Layout>
      <div>
        <p
          style={{
            textAlign: "left",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "center",
            background: "transparent",
          }}
        >
          <span size={2} style={{ fontSize: 18 }}>
            <strong>AVISO</strong>
            <strong>DE</strong>
            <strong>PRIVACIDAD</strong>
          </span>
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          De conformidad con lo dispuesto por la Ley Federal de Protección de
          Datos Personales en Posesión de los Particulares en adelante
          denominada como la “Ley”, se emite el presente Aviso de Privacidad en
          los siguientes términos:
        </p>
        <p
          style={{
            textAlign: "center",
            background: "transparent",
          }}
        >
          <span size={2} style={{ fontSize: 18 }}>
            <strong>FECHA DE ÚLTIMA ACTUALIZACIÓN</strong>
          </span>
        </p>
        <p
          style={{
            textAlign: "center",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          31 de enero de 2025
        </p>
        <p
          style={{
            textAlign: "center",
            background: "transparent",
          }}
        >
          <span size={2} style={{ fontSize: 18 }}>
            <strong>RESPONSABLE</strong>
            <strong>DE</strong>
            <strong>LOS</strong>
            <strong>DATOS</strong>
            <strong>PERSONALES</strong>
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>&nbsp;</p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "center",
            background: "transparent",
          }}
        >
          <span size={2} style={{ fontSize: 18 }}>
            <strong>OBJETO</strong>
          </span>
        </p>
        <p style={{ fontSize: 16 }}>
          &nbsp;Para efectos del presente Aviso de Privacidad,{" "}
          <strong>IMÁGENES MÓVILES DE JALISCO S.A. DE C.V.</strong>, (en
          adelante <strong>“IMJ”),&nbsp;</strong>
          <span size={2}>
            sociedad constituida conforme a las leyes mexicanas,
          </span>
          <strong>&nbsp;</strong>con domicilio fiscal ubicado en Av. Pablo
          Neruda número 2759, Interior 1-A colonia Providencia Cuarta Sección,
          C.P.44639, en la ciudad de Guadalajara, Jalisco, es la Responsable de
          la obtención, divulgación, almacenamiento, uso, manejo,
          aprovechamiento, y/o disposición de los datos personales, laborales y
          empresariales que representan legal o comercialmente a sus Clientes
          y/o Proveedores (en adelante los “<strong>Titulares</strong>”).&nbsp;
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          El presente Aviso de Privacidad tiene por objeto regular el uso y la
          protección de los datos personales del Titular en posesión de la
          Responsable conforme a la Ley.
        </p>
        <p
          style={{
            textAlign: "center",
            background: "transparent",
          }}
        >
          <span size={2} style={{ fontSize: 18 }}>
            <strong>DATOS</strong>
            <strong>RECABADOS</strong>
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>&nbsp;</p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          Los datos personales que la Responsable recaba de los Titulares y/o de
          la Empresa que representan los Titulares son de manera enunciativa mas
          no limitativa los siguientes:
        </p>
        <p
          style={{
            textAlign: "left",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            marginTop: "0.07in",
          }}
        >
          <br />
        </p>
        <h1
          style={{
            textAlign: "center",

            marginTop: "0in",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: "Arial, sans-serif",
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          Datos&nbsp;de&nbsp;la&nbsp;Empresa
        </h1>
        <p
          style={{
            textAlign: "left",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            marginTop: "0.08in",
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "justify",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          (i) Nombre o Razón Social, (ii) domicilio, (iii) número telefónico,
          (iv) referencias de principales clientes, nombre de la compañía,
          dirección, teléfono y años de relación comercial, (v) comprobante de
          domicilio, (vi) registro federal de contribuyentes y cédula de
          identificación fiscal (vii) acta constitutiva, (viii) poder notarial,
          (ix) datos bancarios (en particular la CLABE Interbancaria, Nombre del
          Banco, Número de Cuenta, Tipo de Cuenta y Nombre), (x) estados de
          cuenta bancarios, (xi) opinión de cumplimiento de obligaciones
          fiscales.
        </p>
        <p
          style={{
            textAlign: "left",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            marginTop: "0.07in",
          }}
        >
          <br />
        </p>
        <h1
          style={{
            textAlign: "center",

            marginTop: "0in",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: "Arial, sans-serif",
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          Datos&nbsp;del&nbsp;Titular
        </h1>
        <p
          style={{
            textAlign: "left",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            marginTop: "0.08in",
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "justify",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          (i) Nombre completo e identificación oficial, (ii) domicilio y
          comprobante de domicilio (iii) cargo institucional que desempeña (iv)
          correo electrónico, (v) número telefónico (vi) CURP, (vii) registro
          federal de contribuyentes y cédula de identificación fiscal, (viii)
          estados de cuenta bancarios, (ix) opinión de cumplimiento de
          obligaciones fiscales.
        </p>
        <p
          style={{
            textAlign: "left",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            marginTop: "0.07in",
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "justify",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "265%",
          }}
        >
          El Titular declara que cuenta con la autorización correspondiente para
          transferir los datos de terceros recabados por&nbsp;
          <strong>IMJ</strong>. Sus datos personales o corporativos son
          recabados a través de los siguientes medios: físicamente y/o por
          correo electrónico.
        </p>
        <p
          style={{
            textAlign: "center",
            marginBottom: "0in",
            background: "transparent",
            marginTop: "0.05in",
          }}
        >
          <span size={2} style={{ fontSize: 18 }}>
            <strong>FINALIDAD</strong>
            <strong>DEL</strong>
            <strong>TRATAMIENTO</strong>
            <strong>DE</strong>
            <strong>LOS</strong>
            <strong>DATOS</strong>
            <strong>PERSONALES</strong>
          </span>
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          La información personal del Titular, servirá a la Responsable para (i)
          elaborar una base de datos de los clientes, proveedores y contratistas
          con los que cuenta&nbsp;<strong>IMJ&nbsp;</strong>en beneficio de la
          operación de las áreas internas de&nbsp;<strong>IMJ</strong>, (ii)
          generar y dar seguimiento a las órdenes de compra y de pedido en
          curso, (iii) para investigar y confirmar la buena reputación y
          prestigio comercial del Titular previo a la formalización comercial
          entre la Responsable y el Titular, (iv) para realizar su registro como
          Cliente y/o Proveedor dentro de los sistemas internos de la
          Responsable, (v) para cumplir con las declaraciones fiscales de la
          Responsable, (vi) para la creación de un directorio de Clientes y
          Proveedores con fines estadísticos, financieros, administrativos y de
          calidad exclusivamente para el uso interno de&nbsp;
          <strong>IMJ</strong>., (vii) para enviar información y publicidad
          propia de&nbsp;
          <strong>IMJ</strong> y llevar a cabo su actividad principal y
          proveerle de los productos o servicios que le solicite, requiera y/o
          pueda requerir.&nbsp;
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          De igual forma, dicha información tendrá como finalidad la
          identificación del Titular para su asesoramiento, atención y
          seguimiento; y podrá difundirse exclusivamente a las personas que
          resulten indispensables, a efecto de llevar a cabo las actividades o
          los instrumentos para cumplir con las obligaciones derivadas de la
          actividad principal de la Responsable. La divulgación de los datos que
          han sido proporcionados se realizará atendiendo los principios de
          consentimiento, información, calidad, finalidad, lealtad y
          proporcionalidad, además, servirá como información de contacto,
          elaboración de documentos, diseño de estrategias y, en su caso,
          cobranza.
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          Los datos personales tendrán el uso apropiado para el cual fueron
          obtenidos y podrán ser integrados a una base de datos, misma que será
          creada por la Responsable o algún tercero al cual se le encomiende.
          Dicha base de datos no será del dominio público y podrá tener datos
          adicionales generados por medios propios.
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          De manera adicional, utilizaremos su información personal para las
          siguientes finalidades secundarias que no son necesarias para que la
          Responsable otorgue el producto o brinde el servicio solicitado, sin
          embargo nos permiten y facilitan brindarle una mejor atención:
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          •Informarle u ofrecerle al Titular promociones productos y servicios
          en general, así como cualquier otro producto o servicio relacionado,
          como pueden ser, sin limitación, entrega de promociones, servicios
          especiales, folletos informativos, encuestas y otros productos y
          servicios de la Responsable.
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          •Registro de actividad de visitantes en el sitio web antes de&nbsp;
          <strong>IMJ</strong>.
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          •Todas aquellas similares o análogas a las anteriores.
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          En caso de que desee que sus datos personales no sean tratados para
          estos fines secundarios, desde este momento el Titular nos puede
          comunicar lo anterior a través de un correo electrónico enviado a la
          dirección: avisodeprivacidad@imjmedia.com.mx haciendo alusión en el
          asunto de dicho correo al presente Aviso de Privacidad.
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          La negativa para el uso de sus datos personales para dichas
          finalidades secundarias no podrá ser un motivo para que le neguemos
          los servicios y productos que solicita o contrata con la Responsable.
        </p>
        <p
          style={{
            textAlign: "left",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            marginTop: "0.07in",
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "justify",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          De igual forma los datos personales recabados podrán ser tratados con
          la finalidad de que la Responsable pueda atender requerimientos
          legales de autoridades competentes.
        </p>
        <p
          style={{
            textAlign: "left",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            marginTop: "0.01in",
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "center",
            marginBottom: "0in",
            background: "transparent",
            marginTop: "0.05in",
          }}
        >
          <span size={2} style={{ fontSize: 18 }}>
            <strong>TRANSFERENCIA DE DATOS</strong>
          </span>
        </p>
        <p
          style={{
            textAlign: "left",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
          }}
        >
          Toda transferencia de datos a nuestros colaboradores se realiza
          atendiendo a que asumen las mismas obligaciones que la Responsable, en
          los términos del presente Aviso de Privacidad y de la Ley.
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
          }}
        >
          La transferencia de información personal del Titular a terceros
          (distintos a nuestros colaboradores), se realizará exclusivamente si
          usted da su consentimiento para proporcionarla, a excepción de la
          información a instituciones o autoridades de carácter financiero.
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
          }}
        >
          De igual forma, la Responsable podrá transferir datos e información
          personal del Titular a terceros que tengan relación directa o
          indirecta con la Responsable y la misma sea necesaria para llevar a
          cabo sus actividades.
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
          }}
        >
          Asimismo, la Responsable podrá transferir datos e información personal
          del Titular a sus sociedades filiales y afiliadas, así como a otras
          sociedades que tengan relación con la Responsable. Lo anterior, para
          que le brinden información y avisos respecto a sus actividades.&nbsp;
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "justify",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          Se entenderá que el Titular ha otorgado su consentimiento, sin vicio
          alguno, para la transferencia de datos personales si no manifiesta su
          oposición, de conformidad a lo dispuesto en el tercer párrafo del
          artículo 8 de la Ley.
        </p>
        <p
          style={{
            textAlign: "left",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            marginTop: "0.01in",
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "center",
            marginBottom: "0in",
            background: "transparent",
            marginTop: "0.05in",
          }}
        >
          <span size={2} style={{ fontSize: 18 }}>
            <strong>DATOS PERSONALES SENSIBLES</strong>
          </span>
        </p>
        <p
          style={{
            textAlign: "left",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
          }}
        >
          Le informamos que en caso de ser requeridos datos personales sensibles
          del Titular, de conformidad a la Ley, la Responsable se compromete a
          que los mismos sean tratados bajo medidas de seguridad, siempre
          garantizando su confidencialidad y consentimiento para el tratamiento
          de sus datos. Al hacer uso del sitio web antes mencionado, Usted
          consiente y autoriza que sus datos personales sensibles sean tratados
          conforme a lo previsto en el presente Aviso de Privacidad y bajo lo
          establecido por la Ley.
        </p>
        <p
          style={{
            textAlign: "left",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            marginTop: "0.01in",
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "center",
            marginBottom: "0in",
            background: "transparent",
            marginTop: "0.04in",
          }}
        >
          <span size={2} style={{ fontSize: 18 }}>
            <strong>EXCEPCIONES</strong>
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>&nbsp;</p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          Cabe señalar que de conformidad con la Ley existen supuestos en los
          cuales su consentimiento no es necesario para el tratamiento de sus
          datos personales, y por ello la ausencia del mismo o su negativa en su
          caso, no impide ni impedirá que <strong>IMJ&nbsp;</strong>los trate
          (sus datos personales) en términos de la Ley y demás regulaciones que
          resulten aplicables.
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "left",
            marginBottom: "0in",
            background: "transparent",
            textIndent: "0.06in",

            marginTop: "0.04in",
          }}
        >
          <span size={2} style={{ fontSize: 18 }}>
            <strong>
              OPCIONES Y MEDIOS QUE LA RESPONSABLE OFRECE A LOS TITULARES PARA
              LIMITAR
            </strong>
            <strong>EL</strong>
            <strong>USO</strong>
            <strong>O</strong>
            <strong>DIVULGACIÓN</strong>
            <strong>DE</strong>
            <strong>LOS</strong>
            <strong>DATOS</strong>
            <strong>PERSONALES/CORPORATIVOS.</strong>
          </span>
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          Los datos personales de los Titulares, serán tratados bajo la más
          estricta confidencialidad, cuidando las medidas de seguridad
          administrativas, físicas y técnicas que la Responsable implemente en
          sus políticas y procedimientos de seguridad internos evitando la
          posible divulgación ilícita de datos y limitando su uso a lo
          establecido en el presente Aviso de Privacidad.
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "center",
            marginBottom: "0in",
            background: "transparent",
            marginTop: "0.04in",
          }}
        >
          <span size={2} style={{ fontSize: 18 }}>
            <strong>DERECHOS</strong>
            <strong>ARCO</strong>
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>&nbsp;</p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          Usted tiene derecho a (i) conocer qué datos personales tenemos de
          usted, para qué los utilizamos y las condiciones del uso que les damos
          (Acceso); (ii) solicitar la corrección de su información personal en
          caso de que esté desactualizada, sea inexacta o incompleta
          (Rectificación); (iii) que la eliminemos de nuestros registros o bases
          de datos cuando considere que la misma no está siendo utilizada
          adecuadamente (Cancelación); (iv) así como oponerse al uso de sus
          datos personales para fines específicos (Oposición). Estos derechos se
          conocen como derechos ARCO.
        </p>
        <p
          style={{
            textAlign: "center",
            marginBottom: "0in",
            background: "transparent",
            marginTop: "0.04in",
          }}
        >
          <span size={2} style={{ fontSize: 18 }}>
            <strong>MEDIOS</strong>
            <strong>PARA</strong>
            <strong>EJERCER</strong>
            <strong>LOS</strong>
            <strong>DERECHOS</strong>
            <strong>ARCO</strong>
            <strong>DEL</strong>
            <strong>TITULAR</strong>
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>&nbsp;</p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          En cualquier momento usted o su representante legal debidamente
          acreditado, podrán ejercer los derechos de Acceso, Rectificación
          cuando consideren que son erróneos o inexactos, Cancelación en caso de
          que considere que los datos no son tratados de forma adecuada,
          Oposición en caso de que no quiera que sus datos sean tratados para
          finalidades específicas y/o Revocación cuando ya no sea su deseo como
          Titular que&nbsp;<strong>IMJ&nbsp;</strong>continúe usando sus datos
          personales. El ejercicio de uno de ellos no impide el ejercicio del
          otro y ninguno es presupuesto para el ejercicio de otro.
        </p>
        <p
          style={{
            textAlign: "left",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            marginTop: "0.07in",
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          Para ejercer sus derechos mencionados con anterioridad, deberá
          presentar su solicitud en nuestro domicilio ubicado en Av. Pablo
          Neruda Número 2759, Interior 1 A, Colonia Providencia Cuarta Sección,
          C.P. 44639, Guadalajara, Jalisco, o enviarla a nuestro departamento de
          datos personales al correo electrónico&nbsp;
          <a href="mailto:avisodeprivacidad@imjmedia.com.mx">
            <span style={{ color: "rgb(59, 56, 56)" }}>
              <strong>avisodeprivacidad@imjmedia.com.mx</strong>
            </span>
            ,
          </a>{" "}
          con atención al Departamento de Protección de Datos<strong>.</strong>
        </p>
        <p
          style={{
            textAlign: "left",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            marginTop: "0.06in",
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "justify",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          La solicitud deberá contener los siguientes datos: (i) Nombre del
          titular, (ii) domicilio y dirección de correo electrónico para
          notificarle la respuesta a su solicitud, (iii) una descripción clara y
          precisa de los datos respecto de los cuales se busca ejercer el
          derecho correspondiente, (iv) el objeto de la solicitud, (v) cualquier
          elemento que facilite la localización de los datos, y se deberá anexar
          copia de su identificación, y en su caso el documento mediante el cual
          se acredite la representación legal.
        </p>
        <p
          style={{
            textAlign: "left",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            marginTop: "0.07in",
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "justify",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          El plazo de respuesta de su solicitud será de quince días hábiles
          contados a partir del día en que se recibió la solicitud de acceso,
          rectificación, cancelación y oposición correspondiente. La respuesta
          le será comunicada dentro del plazo antes mencionado a la dirección de
          correo electrónico que proporcionó en su solicitud o enviando carta al
          domicilio proporcionado en la misma. Una vez recibida dicha
          notificación tendrá un plazo de 15 días hábiles para hacerla efectiva
          en caso de ser&nbsp;procedente.
        </p>
        <p
          style={{
            textAlign: "left",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            marginTop: "0.07in",
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "justify",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          En cualquier momento podrá revocar el consentimiento que nos ha
          otorgado para el tratamiento de sus datos personales, de tal forma que
          dejemos hacer uso de los mismos. Para ello deberá presentar una
          solicitud en los términos mencionados para el ejercicio de los
          derechos ARCO. El procedimiento a seguir será el mismo que el previsto
          para el ejercicio de los derechos ARCO. En caso de ser procedente su
          solicitud sus datos dejaran de ser tratados por&nbsp;
          <strong>IMJ</strong>.
        </p>
        <p
          style={{
            textAlign: "center",
            marginBottom: "0in",
            background: "transparent",
            marginTop: "0.04in",
          }}
        >
          <span size={2} style={{ fontSize: 18 }}>
            <strong>CAMBIOS</strong>
            <strong>AL</strong>
            <strong>AVISO</strong>
            <strong>DE</strong>
            <strong>PRIVACIDAD</strong>
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>&nbsp;</p>
        <p
          style={{
            textAlign: "justify",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,

            marginTop: "0.07in",
          }}
        >
          Cualquier cambio al presente Aviso de Privacidad, le será informado a
          través de los siguientes medios:
        </p>
        <p
          style={{
            textAlign: "left",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            marginTop: "0.08in",
          }}
        >
          <br />
        </p>
        <ol>
          <li>
            <p
              style={{
                textAlign: "left",
                marginBottom: "0in",
                background: "transparent",
                marginTop: "0in",
              }}
            >
              <span size={2} style={{ fontSize: 16 }}>
                Mediante Correo electrónico quep ersonalmentele enviemos, y
              </span>
            </p>
          </li>
          <li>
            <p
              style={{
                textAlign: "left",
                marginBottom: "0in",
                background: "transparent",
                marginTop: "0.02in",
              }}
            >
              <span size={2} style={{ fontSize: 16 }}>
                Mediante anuncio en lapágina web de la
                Responsable:https://imjmedia.com.mx
              </span>
            </p>
          </li>
        </ol>
        <p
          style={{
            textAlign: "left",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            marginTop: "0.09in",
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "justify",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          De igual manera, se le informa a los Titulares, que el Aviso de
          Privacidad podrá ser modificado en cualquier momento con la finalidad
          de atender novedades legislativas, así como modificación de políticas
          internas de&nbsp;
          <strong>IMJ</strong>.
        </p>
        <p
          style={{
            textAlign: "left",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            marginTop: "0.07in",
          }}
        >
          <br />
        </p>
        <p
          style={{
            textAlign: "justify",
            marginBottom: "0in",
            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
            lineHeight: "115%",
          }}
        >
          Si se llegaren a modificar las finalidades para las cuales se tratan
          sus datos personales, en caso de ser necesario se solicitará de nuevo
          su consentimiento ya sea expreso o tácito atendiendo al caso
          particular.
        </p>
        <p
          style={{
            textAlign: "center",
            marginBottom: "0in",
            background: "transparent",
            marginTop: "0.04in",
          }}
        >
          <span size={2} style={{ fontSize: 18 }}>
            <strong>CONSENTIMIENTO</strong>
            <strong>DEL</strong>
            <strong>TITULAR</strong>
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>&nbsp;</p>
        <p
          style={{
            textAlign: "justify",

            background: "transparent",
            fontFamily: '"Arial MT", sans-serif',
            fontSize: 16,
          }}
        >
          El Titular manifiesta que (i) el presente Aviso le ha sido dado a
          conocer por la Responsable y (ii) haber leído, entendido y acordado
          los términos expuestos en este Aviso, por lo que otorga su
          consentimiento para el tratamiento de sus Datos Personales de
          conformidad con el presente Aviso de Privacidad de manera tácita al
          enviar a la Responsable sus Datos y Documentos Personales.
        </p>
      </div>
    </Layout>
  );
};

export default Aviso;
