import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import { Link } from "react-router-dom";

import img2 from "../../../components/img/start-campaign-hover.png";
import img1 from "../../../components/img/start-campaign.png";
const StartCampaign = () => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState({
    name: "",
    email: "",
    service: "",
    birthday: null,
    message: "",
  });
  const [token, setToken] = React.useState("");

  const [refreshReCaptcha, setRefreshReCaptcha] = React.useState(false);

  const setTokenFunc = (getToken) => {
    setToken(getToken);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      console.log("value", value, token);
    } catch (error) {
      console.log("error", error);
      setRefreshReCaptcha(!refreshReCaptcha);
    }

    fetch("https://hook.us1.make.com/y5m1qh979naqpf73toqwx8jmq51zbjnx", {
      method: "POST",
      body: JSON.stringify(value),
    })
      .then((data) => {
        console.log(data);
        window.alert(`Form Submited`);
      })
      .catch((err) => {
        console.log(err);
        window.alert(`Error`);
      });
  };
  return (
    <section
      className="relative py-20 xl:pt-[120px] 2xl:pt-[220px] lg:pb-[199px] start-campaign"
      style={{
        background:
          "url(/img/about/campana-bg.png) no-repeat center center / cover",
      }}
    >
      <style>
        {`
          .react-datepicker__year-dropdown-container {
            display: none;
          }

          .react-datepicker__header__dropdown {
            display: flex;
            justify-content: center;
          }

          .react-datepicker__current-month {
            display: none;
          }

          .react-datepicker__day-names {
            display: none;
          }

          .react-datepicker__week {
            display: flex;
            justify-content: center;
          }

          .react-datepicker__day--outside-month {
            visibility: hidden;
          }
        `}
      </style>
      <div className="container relative z-10">
        <div className="flex flex-wrap gap-10">
          <div className="w-full lg:w-1/2 xl:w-[64%] max-w-[840px]">
            <h2 className="text-xl sm:text-2xl font-bold mb-5 md:text-4xl xl:text-[40px] xl:leading-[49px] 2xl:text-[64px] 2xl:leading-[1.5]">
              Comienza tu{" "}
              <span className="inline-block bg-primary text-dark rounded-md md:rounded-[15px] px-3 pb-1 2xl:leading-[86px]">
                campaña
              </span>
            </h2>
            <div className="sm:text-md xl:text-lg 2xl:text-xl 2xl:leading-[1.5] font-medium">
              <p className="mb-8 xl:mb-[44px] 2xl:mb-[86px] max-w-[750px]">
                Transformamos la visibilidad de tu marca con estrategias
                publicitarias innovadoras y ubicaciones estratégicas que
                garantizan un impacto memorable y efectivo.
              </p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:text-md md:font-medium mb-8 xl:mb-[44px] 2xl:mb-[86px]">
              <div className="max-w-[313px]">
                <div className="flex items-start mb-7 gap-2">
                  <Link
                    to="https://maps.app.goo.gl/aNyVpwvv1FcDK2JW8"
                    target="_blank"
                  >
                    <img src="/img/about/pin.png" alt="" />
                  </Link>
                  <div className="w-0 flex-grow">
                    <p>
                      Av. Ávila Camacho #2247 Col. Country  Club CP 44610,
                      Guadalajara, Jalisco
                    </p>
                  </div>
                </div>
                <Link
                  to="tel:(33) 3640-0944"
                  className="flex items-center gap-2 mb-2"
                >
                  <img src="/img/about/phone.png" alt="" />
                  <div className="w-0 flex-grow">(33) 3640-0944</div>
                </Link>
                <Link
                  to="tel:(33) 1813-4067"
                  className="flex items-center gap-2 mb-2"
                >
                  <img src="/img/about/phone.png" alt="" />
                  <div className="w-0 flex-grow">(33) 1813-4067</div>
                </Link>
                <Link
                  to="tel:800-055-33-33"
                  className="flex items-center gap-2"
                >
                  <img src="/img/about/phone.png" alt="" />
                  <div className="w-0 flex-grow">800-055-33-33</div>
                </Link>
              </div>
              <div className="max-w-[313px]">
                <div className="flex items-start mb-7 gap-2">
                  <Link
                    to="https://maps.app.goo.gl/rGZyhX8V4CiWr1aW7"
                    target="_blank"
                  >
                    <img src="/img/about/pin.png" alt="" />
                  </Link>
                  <div className="w-0 flex-grow">
                    Paseo de las Palmas #239, int. 204 Col. Lomas de Chapultepec
                    C.P. 11000, CDMX
                  </div>
                </div>
                <Link
                  to="tel:(55)-5293-8260"
                  className="flex items-center gap-2 mb-2"
                >
                  <img src="/img/about/phone.png" alt="" />
                  <div className="w-0 flex-grow">(55)-5293-8260</div>
                </Link>
                <Link
                  to="tel:(55)-5293-8265"
                  className="flex items-center gap-2"
                >
                  <img src="/img/about/phone.png" alt="" />
                  <div className="w-0 flex-grow">(55)-5293-8265</div>
                </Link>
              </div>
            </div>
            <ul className="flex gap-5 flex-wrap">
              {social.map((item, index) => (
                <li key={index}>
                  <Link to={item.url} className="max-sm:w-[40px] block">
                    <img src={item.img} className="max-w-full" alt="" />
                  </Link>
                </li>
              ))}
            </ul>
            <div className="mt-7 text-md font-medium leading-[1.5]">
              <Link to="mailto:contacto@imjmedia.com.mx" className="text-black">
                contacto@imjmedia.com.mx
              </Link>
            </div>
          </div>
          <div className="w-0 flex-grow lg:max-w-[626px]">
            <form onSubmit={handleSubmit}>
              <div className="mb-6">
                <label className="font-medium mb-2 block">Nombre*</label>
                <input
                  type="text"
                  className="form-input"
                  placeholder="Jane"
                  value={value.name}
                  onChange={(e) => setValue({ ...value, name: e.target.value })}
                  required
                />
              </div>
              <div className="mb-6">
                <label className="font-medium mb-2 block">Correo*</label>
                <input
                  type="text"
                  className="form-input"
                  placeholder="email@janesfakedomain.net"
                  value={value.email}
                  onChange={(e) =>
                    setValue({ ...value, email: e.target.value })
                  }
                  required
                />
              </div>
              <div className="mb-6">
                <label className="font-medium mb-2 block">
                  Selecciona el servicio que te interesa*
                </label>
                <select
                  className="form-input"
                  value={value.service}
                  onChange={(e) =>
                    setValue({ ...value, service: e.target.value })
                  }
                  required
                >
                  <option value="">Seleccionar servicio</option>
                  <option value="Muros">Muros</option>
                  <option value="Autobuses">Autobuses</option>
                  <option value="Vallas">Vallas</option>
                  <option value="Espectaculares">Espectaculares</option>
                  <option value="Puentes peatonales">Puentes peatonales</option>
                  <option value="Pantallas digitales">
                    Pantallas digitales
                  </option>
                  <option value="Aeropuertos">Aeropuertos</option>
                  <option value="Centros comerciales">
                    Centros comerciales
                  </option>
                </select>
              </div>
              <div className="mb-6">
                <label className="font-medium mb-2 block">
                  ¿Cuándo es tu cumpleaños?
                </label>
                <DatePicker
                  selected={value.birthday}
                  onChange={(date) => setValue({ ...value, birthday: date })}
                  dateFormat="dd/MM"
                  showYearDropdown={false}
                  showMonthDropdown
                  showDayDropdown
                  className="form-input"
                  placeholderText="Selecciona una fecha"
                />
              </div>
              <div className="mb-6">
                <label className="font-medium mb-2 block">
                  ¿Cómo te podemos ayudar?*
                </label>
                <textarea
                  className="form-input h-[75px]"
                  placeholder="Platícanos sobre tu campaña"
                  value={value.message}
                  onChange={(e) =>
                    setValue({ ...value, message: e.target.value })
                  }
                >
                  {value.message}
                </textarea>
              </div>
              <GoogleReCaptchaProvider reCaptchaKey="6LeBpBwqAAAAAObJgJZtMnWX0vslJOsfmETpms5X">
                <GoogleReCaptcha
                  className="google-recaptcha-custom-class"
                  onVerify={setTokenFunc}
                  refreshReCaptcha={refreshReCaptcha}
                />
              </GoogleReCaptchaProvider>
              <button
                type="submit"
                className="btn text-xs px-4 md:text-md md:h-[50px] lg:text-[20px] lg:h-[62px] w-full rounded-lg"
              >
                Comenzar campaña
              </button>
            </form>
          </div>
        </div>
      </div>
      <img
        src={open ? img2 : img1}
        className="absolute bottom-0 right-[calc(50%+60px)] max-2xl:right-[40%] max-xl:w-[280px] max-xl:right-[48%] hidden lg:block"
        alt=""
        onMouseEnter={() => setOpen(true)}
      />
    </section>
  );
};

const social = [
  {
    img: "/img/about/social/1.png",
    url: "",
  },
  {
    img: "/img/about/social/2.png",
    url: "",
  },
  {
    img: "/img/about/social/3.png",
    url: "",
  },
];

export default StartCampaign;
