import React from "react";

import infografia from "../img/infografia_desktop.jpg";
import infografiaMobile from '../img/infografia_mobile.jpg';
const Technologia = () => {
	return (
		<section className="pt-10 sm:pt-20 2xl:pt-[118px] pb-[9px]">
			<div className="before:absolute before:inset-0 relative mt-24 hidden lg:flex">
								<img src={infografia} className="max-w-full" alt="" />
							</div>
							<div className="before:absolute before:inset-0 relative  mt-24 flex lg:hidden">
								<img src={infografiaMobile} className="max-w-full" alt="" />
							</div>
		</section>
	);
};

export default Technologia;
